import L from "leaflet";
import "leaflet.markercluster";
import "leaflet-providers";
import axios from "axios";
const configgg = require("./config.json");
import { Component } from "./components/GenericComponent";
import { FieldsListComponent } from "./components/FieldsList";
import { loadAndDisplayMarkers } from "./initMap";
import {
  allFieldsConfig,
  TextFieldEditComponent,
  PasswordFieldEditComponent,
  CheckboxFieldEditComponent,
} from "./components/formFields";

export const qs = document.querySelector.bind(document);
export const qsa = document.querySelectorAll.bind(document);

const SITENAME = "archimapa";
export const API_URL = "https://map.transsearch.net";

var site_specific_funcs;

(function () {
  var buildingIcon = L.icon({
    iconUrl: "/archimapa/building.png",
    iconSize: [30, 30],
    iconAnchor: [10, 10],
    //popupAnchor: [5, 10],
    //shadowSize: [30, 95],
    //shadowAnchor: [22, 94]
  });
  var industryIcon = L.icon({
    iconUrl: "/archimapa/factory.png",
    iconSize: [30, 30],
    iconAnchor: [10, 10],
    //popupAnchor: [5, 10],
    //shadowSize: [30, 95],
    //shadowAnchor: [22, 94]
  });

  window.site_specific_funcs = {
    getMarkerIcon: (obj_data) => {
      //console.log('.........../././', obj_data);
      return obj_data.fields.building_type == 1 ? buildingIcon : industryIcon;
    },
  };
})();

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; SameSite=Lax; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

let token = getCookie("jwt_auth");

const setToken = (newToken) => {
  token = newToken;
  setCookie("jwt_auth", token, 7);
  global_data.signupComponent.render();
};

const setOnClick = (root, str, handler) => {
  const node = root.querySelector(str);
  if (!node) return;
  node.onclick = handler;
};

const objToFormData = (obj) => {
  const fd = new FormData();
  for (let i in obj) {
    fd.append(i, obj[i]);
  }
  return fd;
};

/*

Child: {
    getData: () => any;
    selector: string;
    component: Component;
}
*/

const commonFieldsConfig = [
  {
    key: "title",
    type: "text",
    title: "Назва",
  },
];

const get_ps_fields = () => {};

class FieldsEditListComponent extends FieldsListComponent {
  getValues = () => {
    const virtualFieldsValues = {};
    const items = [];
    this.renderedChildren.forEach((child) => {
      if (!child) return;
      const val = child.getValue();
      virtualFieldsValues[child.data.key] = val;
      if (child.data.category_id) {
        items.push(val);
      }
    });
    console.log("=== FORM_VALUES_GET: ", virtualFieldsValues);
    return [virtualFieldsValues, items];
  };
}

export class GenericPopupComponent extends Component {
  getChildren = () => {
    return [
      {
        selector: ".some-popup-content",
        component: this.data.contentComponent,
        getData: () => {
          return { ...this.data.data, close: this.hide.bind(this) };
        },
      },
    ];
  };
  getHTML = () => {
    return `
        <div class="some-popup">

        <div class="some-popup-inside">
            <div class="some-popup-overlay">
            </div>
            <div class="some-popup-body">
                <div class="close-popup">
                    ${getl("close")}
                </div>
                <div class="some-popup-content">
                    
                </div>
            </div>
        </div>
    </div>
        `;
  };
  show = () => {
    show(this.mountNode.children[0]);
  };
  hide = () => {
    hide(this.mountNode.children[0]);
  };
  afterRender = () => {
    const confirmAndClose = () => {
      if (confirm(getl("do-you-want-to-close"))) {
        this.renderedChildren[0].onClose
          ? this.renderedChildren[0].onClose()
          : null;
        this.hide();
      }
    };
    this.mountNode.querySelector(".some-popup-overlay").onclick =
      confirmAndClose;
    this.mountNode.querySelector(".close-popup").onclick = confirmAndClose;
  };
}

export class AddPhotoComponent extends Component {
  getHTML = () => {
    return `
        <div "class=some-popup">
            Add photo
        </div>
        `;
  };
}
export class EditObjectComponent extends Component {
  getHTML = () => {
    console.log("---- <DATA>", this.data);
    return `
        <div class="edit-object-popup">
            <div class="edit-object-form">
                <h2>
                    ${getl("edit-object-form-title")}
                </h2>
                <div class="form-field">
                    <div class="form-caption">
                        Назва
                    </div>
                    <div class="form-value">
                        <input type="text" name="title" form-val value="${
                          this.data.title
                        }">
                    </div>
                </div>
                <div class="form-field">
                    <div class="form-caption">
                        Опис
                    </div>
                    <div class="form-value">
                        <textarea name="description" form-val>${
                          this.data.description
                        }</textarea>
                    </div>
                </div>
                <div class="form-field">
                    <div class="form-caption">
                        Координати
                    </div>
                    <div class="form-value">
                        <div>Широта: <input type="text" name="latitude" value="${
                          this.data.latitude
                        }" form-val /></div>
                        <div>Довгота: <input type="text" name="longitude" value="${
                          this.data.longitude
                        }" form-val /></div>
                    </div>
                </div>
                <div class="form-virtual-fields-list">
                </div>
                <div class="form-errors">

                </div>
                <div class="form-field center">
                    <button class="submit-new-object-button">
                        Додати
                    </button>
                </div>
            </div>
        </div>
        `;
  };
  afterRender = () => {
    const fieldsEdit = new FieldsEditListComponent(
      qs(".form-virtual-fields-list"),
      {
        fieldsConfig: getFieldsListConfig(this.data.fields, "edit"),
      }
    );
    fieldsEdit.render();

    qs(".submit-new-object-button").onclick = () => {
      const vals = {};
      hide(this.qs(".form-errors"));
      this.qsa("[form-val]").forEach((node) => {
        const key = node.getAttribute("name");
        const val = node.value;
        vals[key] = val;
      });
      let err;
      if (!vals.title) {
        err = getl("please-enter-title");
      }
      if (!vals.latitude) {
        err = getl("please-enter-lat");
      }
      if (!vals.longitude) {
        err = getl("please-enter-lng");
      }
      if (err) {
        qs(".edit-object-form .form-errors").innerHTML = err;
        show(qs(".edit-object-form .form-errors"));
        return;
      }
      const [virtualFieldsValues, items] = fieldsEdit.getValues();
      vals.customFields = JSON.stringify(virtualFieldsValues);
      vals.items = items;

      //const files = uploadImage.getFiles();
      editObject(this.data.id, vals /*, files*/)
        .then((res) => {
          global_data.showNotification(
            getl("object_successfully_edited"),
            "success"
          );
        })
        .catch((err) => {
          global_data.showNotification(err, "error");
          this.qs(".form-errors").innerHTML = err;
          show(this.qs(".form-errors"));
        });
    };
  };
}

class LoginPopupComponent extends Component {
  getHTML = () => {
    return `
        <div class="login-popup-root">
            <h3>
                ${getl("login")}
            </h3>
            <div>
                <div class="form-fields">
                </div>
                <div class="form-errors">

                </div>
                <div>   
                    <button class="submit-button">${getl("submit")}</button>
                </div>
            </div>
        </div>
        `;
  };
  afterRender = () => {
    this.mountNode.querySelector(".submit-button").onclick = () => {
      hide(this.mountNode.querySelector(".form-errors"));
      const [vals] = this.renderedChildren[0].getValues();
      const { username, password, rememberMe } = vals;
      let err;
      if (!username) {
        err = getl("please_enter_login");
      }
      if (!password) {
        err = getl("please_enter_password");
      }
      if (err) {
        this.mountNode.querySelector(".form-errors").innerHTML = err;
        show(this.mountNode.querySelector(".form-errors"));
      } else {
        axios
          .post(
            API_URL + "/auth/login",
            objToFormData({
              username,
              password,
              rememberMe: Number(rememberMe),
            })
          )
          .then((res) => {
            setToken(res.data.token);
            this.data.close();
            showNotification(get("you_successfully_logged_in"), "success");
          })
          .catch((e) => {
            show(this.mountNode.querySelector(".form-errors"));
            this.mountNode.querySelector(".form-errors").innerHTML =
              e.text || getl("could_not_log_in");
          });
      }
    };
  };
  getChildren = () => {
    return [
      {
        selector: ".form-fields",
        component: FieldsEditListComponent,
        getData: () => {
          return {
            fieldsConfig: [
              {
                value: "",
                title: getl("username"),
                key: "username",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("password"),
                key: "password",
                component: PasswordFieldEditComponent,
              },
              {
                value: false,
                title: getl("rememberMe"),
                key: "rememberMe",
                component: CheckboxFieldEditComponent,
              },
            ],
          };
        },
      },
    ];
  };
}

class SignupPopupComponent extends Component {
  getHTML = () => {
    return `
        <div class="login-popup-root">
            <h3>
                ${getl("signup")}
            </h3>
            <div>
                <div class="form-fields">
                </div>
                <div class="form-errors">

                </div>
                <div>   
                    <button class="submit-button">${getl("submit")}</button>
                </div>
            </div>
        </div>
        `;
  };
  afterRender = () => {
    this.mountNode.querySelector(".submit-button").onclick = () => {
      hide(this.mountNode.querySelector(".form-errors"));
      const [vals] = this.renderedChildren[0].getValues();
      const { username, password, rememberMe } = vals;
      let err;
      if (!username) {
        err = getl("please_enter_login");
      }
      if (!password) {
        err = getl("please_enter_password");
      }
      if (err) {
        this.mountNode.querySelector(".form-errors").innerHTML = err;
        show(this.mountNode.querySelector(".form-errors"));
      } else {
        axios({
          type: "POST",
          url: "/auth/login",
          data: {
            username,
            password,
            rememberMe,
          },
        })
          .then(() => {
            this.data.close();
            showNotification(get("you_successfully_logged_in"), "success");
          })
          .catch((e) => {
            show(this.mountNode.querySelector(".form-errors"));
            this.mountNode.querySelector(".form-errors").innerHTML =
              e.text || getl("could_not_log_in");
          });
      }
    };
  };
  getChildren = () => {
    return [
      {
        selector: ".form-fields",
        component: FieldsEditListComponent,
        getData: () => {
          return {
            fieldsConfig: [
              {
                value: "",
                title: getl("username"),
                key: "username",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("email"),
                key: "email",
                component: TextFieldEditComponent,
              },
              {
                value: "",
                title: getl("password"),
                key: "password",
                component: PasswordFieldEditComponent,
              },
              {
                value: "",
                title: getl("repeat_password"),
                key: "repeat_password",
                component: PasswordFieldEditComponent,
              },
            ],
          };
        },
      },
    ];
  };
}

export class PleaseLoginPopupComponent extends Component {
  getHTML = () => {
    return `
        <div class="please-login-popup-root">
            ${getl("please_login_or_signup_to_add_objects")}
           <div>
            <button class="login-button">
                ${getl("login")}
            </button>
            <button class="signup-button">
                ${getl("signup")}
            </button>
           </div>
        </div>
        `;
  };
  afterRender = () => {
    let l, s;
    if ((l = this.mountNode.querySelector(".login-button"))) {
      l.onclick = () => {
        console.log("///", global_data.loginPopup);
        global_data.loginPopup ? global_data.loginPopup.show() : null;
        this.data.close();
      };
    }
    if ((s = this.mountNode.querySelector(".signup-button"))) {
      s.onclick = () => {
        global_data.signupPopup ? global_data.signupPopup.show() : null;
        this.data.close();
      };
    }
  };
}

export class SignupComponent extends Component {
  getHTML = () => {
    return (
      `
        <div class="login-signup-root">
            ` +
      (!token
        ? `<button class="login-button">
                    ${getl("login")}
                </button>
                <button class="signup-button">
                    ${getl("signup")}
                </button>`
        : `
                <button class="logout-button">
                    ${getl("logout")}
                </button>`) +
      `
            <div class="login-popup">
            </div>
            <div class="signup-popup">
            </div>
        </div>
        `
    );
  };
  afterRender = () => {
    //console.log("----------------------> AFTER RENDER");
    global_data.loginPopup = this.renderedChildren[0];
    global_data.signupPopup = this.renderedChildren[1];
    setOnClick(this.mountNode, ".login-button", () => {
      this.renderedChildren[0].show();
    });
    setOnClick(this.mountNode, ".signup-button", () => {
      this.renderedChildren[1].show();
    });
    setOnClick(this.mountNode, ".logout-button", () => {
      setToken(false);
    });
    //console.log("---------------------->", this.renderedChildren);
  };
  getChildren = () => {
    return [
      {
        component: GenericPopupComponent,
        selector: ".login-popup",
        getData: () => {
          return {
            contentComponent: LoginPopupComponent,
            data: {
              close: () => {
                this.renderedChildren[0].hide();
              },
            },
          };
        },
      },
      {
        component: GenericPopupComponent,
        selector: ".signup-popup",
        getData: () => {
          return {
            contentComponent: SignupPopupComponent,
            data: {
              foo: "bar",
            },
          };
        },
      },
    ];
  };
}

export const getFieldsListConfig = (values, type = "display") => {
  return getSiteSpecific("objectCustomFields", []).map((field) => ({
    value: values[field.key] || "",
    key: field.key,
    component: allFieldsConfig[field.type][type],
    title: field.title,
    field_data: field.field_data,
  }));
};

const removeAllMarkers = () => {
  for (let m in window.global_data.markersPool) {
    global_data.map.removeLayer(window.global_data.markersPool[m]);
  }
};

window.global_data = {
  showNotification: (msg, type) => {
    var n = document.createElement("div");
    n.innerHTML = msg;
    n.classList.add(type);
    qs("#notifications").appendChild(n);
    setTimeout(() => {
      qs("#notifications").removeChild(n);
    }, 1000);
  },
  isLoggedIn: () => {
    console.log("token", token);
    return !!token;
  },
  markersPool: {},
  siteSpecificConfigs: require("./archimapa/config.json"),
  setFilters: (new_filters) => {
    // refresh markers
    removeAllMarkers();
    window.global_data.filters = new_filters;
    loadAndDisplayMarkers();
  },
};

const loadTranslations = async () => {
  global_data.texts = configgg.texts;
};

export const getSiteSpecific = (key, fallback) => {
  if (!global_data.siteSpecificConfigs) {
    debugger;
  }
  return global_data.siteSpecificConfigs[key] !== undefined
    ? global_data.siteSpecificConfigs[key]
    : fallback;
};

export const runSiteSpecific = (key, ...args) => {
  if (!window.site_specific_funcs || !window.site_specific_funcs[key]) {
    console.error(
      "Cannot run site specific: " + key,
      window.site_specific_funcs
    );
    return;
  }
  const res = window.site_specific_funcs[key](...args);
  return res;
};

const fieldsConfig = [
  {
    key: "date_build",
    title: "Дата побудови",
    type: "date",
  },
  {
    key: "rating",
    title: "Рейтинг",
    type: "number",
  },
  {
    key: "is_exist",
    title: "Діючий",
    type: "boolean",
  },
  {
    key: "architect_name",
    title: "Архітектор",
    type: "string",
  },
];

export const getUrlParam = (key) => {
  var url = new URL(window.location);
  var c = url.searchParams.get(key);
  console.log(c);
  return c;
};

const show = (node) => {
  node.style.display = "block";
};
const hide = (node) => {
  node.style.display = "none";
};

class ImageUploadComponent extends Component {
  getHTML = () => {
    return `
            <div>
                <div class="form-caption">
                    Завантажити фото
                </div>
                <div class="form-value">
                    <input class="image-select" type="file" multiple />
                </div>
            </div>
        `;
  };
  getFiles() {
    return this.mountNode.querySelector(".image-select").files;
  }
}

export const getl = (key) => {
  return global_data.texts[key] || key;
};

const addTranslations = async () => {
  return loadTranslations().then(() => {
    qsa("[data-tlname]").forEach((node) => {
      const key = node.getAttribute("data-tlname");
      const tl = global_data.texts[key];
      node.innerHTML = tl;
    });
  });
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const submitNewObject = async (vals, files) => {
  const fd = new FormData();
  for (let k in vals) {
    fd.append(k, vals[k]);
  }
  for (var i in files) {
    if (files.hasOwnProperty(i)) {
      //const f = await toBase64(files[i]);
      fd.append("photo" + i, files[i]);
    }
  }
  return axios({
    method: "post",
    url: API_URL + "/objects/add",
    data: fd,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const editObject = (id, vals) => {
  const fd = new FormData();
  for (let k in vals) {
    fd.append(k, vals[k]);
  }
  /*for (var i in files) {
        if (files.hasOwnProperty(i)) {
            fd.append("photo" + i, files[i]);
        }
    }*/
  console.log("FormData", fd);
  return axios({
    method: "post",
    url: API_URL + "/objects/edit/" + id,
    data: fd,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

const displayAddNewObjectPopup = (lat, lng) => {
  qs(".add-new-object-popup").style.display = "block";
  qs(".add-new-object-popup-content").innerHTML = `
    
    <div class="new-object-form">
        <h2>
            ${getl("add-new-object-form-title")}
        </h2>
        <div class="form-field">
            <div class="form-caption">
                Назва
            </div>
            <div class="form-value">
                <input type="text" name="title" form-val>
            </div>
        </div>
        <div class="form-field">
            <div class="form-caption">
                Опис
            </div>
            <div class="form-value">
                <textarea name="description" form-val>

                </textarea>
            </div>
        </div>
        <div class="form-field">
            <div class="form-caption">
                Координати
            </div>
            <div class="form-value">
                <div>Широта: <input type="text" name="latitude" value="${lat}" form-val /></div>
                <div>Довгота: <input type="text" name="longitude" value="${lng}" form-val /></div>
            </div>
        </div>
        <div class="form-virtual-fields-list">
        </div>
        <div class="form-field">
            <div class="select-image-wrapper"></div>
        </div>
        <div class="form-errors">

        </div>
        <div class="form-field center">
            <button class="cancel-new-object-button">
                ${getl("cancel")}
            </button>
            <button class="submit-new-object-button">
                ${getl("add_new_object")}
            </button>
        </div>
    </div>
    
    `;

  const uploadImage = new ImageUploadComponent(qs(".select-image-wrapper"));
  uploadImage.render();

  const fieldsEdit = new FieldsEditListComponent(
    qs(".form-virtual-fields-list"),
    {
      fieldsConfig: getFieldsListConfig({}, "edit"),
    }
  );
  fieldsEdit.render();

  qs(".cancel-new-object-button").onclick = () => {
    if (confirm(getl("do-you-want-to-close"))) {
      hideAddNewObjectPopup();
      backFromSelectPointState();
    }
  };

  qs(".submit-new-object-button").onclick = () => {
    const vals = {};
    hide(qs(".form-errors"));
    qsa(".new-object-form [form-val]").forEach((node) => {
      const key = node.getAttribute("name");
      const val = node.value;
      vals[key] = val;
    });
    let err;
    if (!vals.title) {
      err = getl("please-enter-title");
    }
    if (!vals.latitude) {
      err = getl("please-enter-lat");
    }
    if (!vals.longitude) {
      err = getl("please-enter-lng");
    }
    if (err) {
      qs(".form-errors").innerHTML = err;
      show(qs(".form-errors"));
      return;
    }
    const [virtualFieldsValues, items] = fieldsEdit.getValues();
    vals.customFields = JSON.stringify(virtualFieldsValues);
    console.log("items", items);
    vals.items = items;

    const files = uploadImage.getFiles();
    submitNewObject(vals, files)
      .then((res) => {
        global_data.showNotification(
          getl("object_successfully_added"),
          "success"
        );
        hideAddNewObjectPopup();
        backFromSelectPointState();
        loadAndDisplayMarkers();
      })
      .catch((err) => {
        global_data.showNotification(err, "error");
        qs(".form-errors").innerHTML = err;
        show(qs(".form-errors"));
      });
  };
};
const hideAddNewObjectPopup = () => {
  qs(".add-new-object-popup").style.display = "none";
};

const switchToSelectPointState = () => {
  qs(".please-select-point").style.display = "block";
  qs(".add-object-button-wrapper").style.display = "none";
  show(qs(".click-on-map-overlay"));
  qs("#map").classList.add("select-point");
  global_data.map.on("click", displayAMarkerForNewObject);
};
const backFromSelectPointState = () => {
  qs(".please-select-point").style.display = "none";
  qs(".add-object-button-wrapper").style.display = "block";
  qs("#map").classList.remove("select-point");
  global_data.map.off("click", displayAMarkerForNewObject);
  hide(qs(".click-on-map-overlay"));
};

let clicked = false;

const displayAMarkerForNewObject = (val) => {
  hide(qs(".click-on-map-overlay"));
  if (clicked) return;
  clicked = true;
  const latlng = L.latLng(val.latlng.lat, val.latlng.lng);
  const marker = L.marker(latlng).addTo(global_data.map);
  marker.addTo(global_data.map);
  setTimeout(() => {
    clicked = false;
    displayAddNewObjectPopup(val.latlng.lat, val.latlng.lng);
    setTimeout(() => {
      marker.removeFrom(global_data.map);
    }, 100);
  }, 500);
};

export const attachAddNewObjectHandlers = (showPleaseLoginPopup) => {
  qs(".add-object-button").onclick = () => {
    if (global_data.isLoggedIn()) {
      switchToSelectPointState();
    } else {
      showPleaseLoginPopup();
    }
  };

  qs(".add-new-object-popup-overlay").onclick = () => {
    if (confirm(getl("do-you-want-to-close"))) {
      hideAddNewObjectPopup();
      backFromSelectPointState();
    }
  };
  /*qs('.please-select-point').onclick = () => {
            displayAddNewObjectPopup();
            backFromSelectPointState();
        }*/
};

export const initPage = async () => {
  return Promise.all([addTranslations()]);
};
