import { Component } from "./GenericComponent";
import { loadObjectData } from "../api";
import { getFieldsListConfig } from "../main_module";
import { FieldsListComponent } from "./FieldsList";

export class ObjectPopupComponent extends Component {
  onCreate() {
    loadObjectData(this.data.id).then((res) => {
      this.data = { ...this.data, ...res };
      this.render();
    });
  }
  getHTML = () => {
    return `
            <div class="object-popup-content">
                <a href="/object.html?id=${this.data.id}"><h2>${
      this.data.title
    }</h2></a>
                <div>
                    ${this.data.description}
                </div>
                <div class="object-popup-fields-list">

                </div>
                <div class="object-popup-photos">
                    ${this.data?.images
                      .map(
                        (i) =>
                          `<a href="${API_URL + i.full_url}"><img src="${
                            API_URL + i.preview_url
                          }" /></a>`
                      )
                      .join("")}
                </div>
            </div>
        `;
  };
  getChildren = () => {
    console.log("popup get data");
    return [
      {
        component: FieldsListComponent,
        selector: ".object-popup-fields-list",
        getData: () => {
          const values = this.data.fields || {};
          return {
            fieldsConfig: getFieldsListConfig(values),
          };
        },
      },
    ];
  };
  afterRender = () => {
    lightGallery(this.qs(".object-popup-photos"));
  };
}
