import axios from "axios";
import { API_URL } from "./main_module";

export function fetchItemsByCategory(id) {
  return axios(API_URL + "/items/category/" + id);
}

export const unpackObject = (obj) => {
  return { ...obj, fields: JSON.parse(obj.custom_fields || "{}") };
};

export const loadObjectData = (object_id) => {
  console.log("loading object", object_id);
  return axios({
    method: "get",
    url: API_URL + "/objects/" + object_id,
  }).then((res) => {
    console.log("????", JSON.parse(res.data.custom_fields || "{}"));
    return unpackObject(res.data);
  });
};

export const loadObjects = async (data) =>
  axios({
    method: "get",
    url: API_URL + "/objects?" + new URLSearchParams(data).toString(),
  })
    .then((res) => {
      return res.data.map((obj) => unpackObject(obj));
    })
    .catch((e) => {
      return [];
    });
