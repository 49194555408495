import {
  GenericPopupComponent,
  initPage,
  PleaseLoginPopupComponent,
  SignupComponent,
  attachAddNewObjectHandlers,
} from "./main_module";
import { MapAndFiltersComponent } from "./components/MapAndFiltersComponent";

const onPageLoad = async () => {
  await initPage();

  const pleaseLoginPopup = new GenericPopupComponent("#please-login-block", {
    contentComponent: PleaseLoginPopupComponent,
    data: {},
  });
  pleaseLoginPopup.render();
  attachAddNewObjectHandlers(pleaseLoginPopup.show.bind(pleaseLoginPopup));

  const signup = new SignupComponent("#login-signup-block");
  window.global_data.signupComponent = signup;
  signup.render();

  const mapAndFilters = new MapAndFiltersComponent(".map-and-filters").render();
};

onPageLoad();
