import { Component } from "./GenericComponent";

export class FieldsListComponent extends Component {
  name = "FieldsListComponent";
  getChildren = () => {
    return this.data.fieldsConfig.map((field) => {
      return {
        getData: () => {
          return {
            value: field.value || "",
            title: field.title,
            key: field.key,
            ...(field.field_data || {}),
          };
        },
        selector: ".fields-list-field-root-" + field.key,
        component: field.component,
      };
    });
  };
  getHTML = () => {
    return (
      `
              <ul class="fields-list">
                  ` +
      this.data.fieldsConfig
        .map((field) => `<li class="fields-list-field-root-${field.key}"></li>`)
        .join("") +
      `
              </ul>
          `
    );
  };
}
