import { Component } from "./GenericComponent";
import { FiltersWidgetComponent } from "./FiltersWidget";
import { initMap } from "../initMap";

export class MapAndFiltersComponent extends Component {
  getHTML() {
    return `
        <div class="map-wrapper">
            <div id="map">
            
            </div>
        </div>
        <div class="filters">

        </div>`;
  }
  getChildren = () => {
    return [
      {
        component: FiltersWidgetComponent,
        selector: ".filters",
        getData: () => {
          return {};
        },
      },
    ];
  };
  afterRender() {
    this.map = initMap();
  }
}
