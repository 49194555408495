{
    "texts": {
        "title": "Архітектурна карта України",
        "add-object": "Додати об'єкт",
        "please-click-on-map": "Оберіть на карті місце розташування об'єкта, який ви хочете додати",
        "do-you-want-to-close": "Ви справді хочете закрити форму? Усі введені дані буде втрачено",
        "add-new-object-form-title": "Додавання нового об'єкту",
        "please-enter-title": "Будь-ласка введіть назву об'єкту",
        "please-enter-lat": "Будь-ласка введіть широту",
        "please-enter-lng": "Будь-ласка введіть довготу",
        "add_photo": "Додати фото",
        "edit_object": "Редагувати об'єкт",
        "foo": "бар"
    }
}